<template>
  <div>
      <b-loading :is-full-page="true" :active="(courses||[]).length===0"></b-loading>
      <div class="columns mt-2 ml-1">
        <a class="button is-primary" @click="editNewCourse" v-if="$api.canEdit()">{{ $t('courses.addNewCourse') }}</a>
      </div>
      <div class="columns is-multiline mt-2">
        <div class="column is-one-quarter"
             v-for="course in ($api.canEdit()?courses:courses.filter(x=>assignedCoursesIds.includes(x.id)))"
             :key="course.id">
          <div class="card is-shadowless bumped-on-hover" style="border: 2px dashed rgba(0,0,0,0.13);"
               :class="{'disabled-course':!assignedCoursesIds.includes(course.id)&&!$api.canEdit()}">
            <div class="card-image">
              <figure class="image is-4by3">
                <img v-if="course.icon_url==null||course.icon_url.length===0"
                     src="https://bulma.io/images/placeholders/1280x960.png"
                     :alt="course.name" @click="goToCourse(course)" style="cursor: pointer">
                <img v-if="course.icon_url!=null&&course.icon_url.length>0"
                     :src="$api.config.asset_endpoint + course.icon_url" :alt="course.name"
                     @click="goToCourse(course)" style="cursor: pointer">
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <a class="title is-4 link" @click="goToCourse(course)">{{ course.name }}</a>
                </div>
              </div>
              <div class="content" style="margin-top: -16px;">
                <div v-html="course.description" style="max-height: 88px; overflow: hidden"/>
                <span v-if="$api.user.is_admin || $api.user.is_writer"> <br/><small><a
                    @click="editCourse(course)">{{ $t('courses.settings') }}</a></small></span>
              </div>
            </div>
          </div>
        </div>
      </div>

    <b-modal
        :active="showEditingModal"
        has-modal-card
        trap-focus
        scroll="keep"
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Modal"
        close-button-aria-label="Close"
        @close="showEditingModal = false"
        aria-modal>
      <div class="card" v-if="selectedCourse != null">
        <div class="card-content" style=" overflow-y: scroll; max-height: 90vh">

          <div class="columns mt-2">
            <div class="column is-half">
              <img v-if="selectedCourse.icon_url!=null&&selectedCourse.icon_url.length>0"
                   :src="$api.config.asset_endpoint + selectedCourse.icon_url"
                   width="200px"><br/>
              <div class="file">
                <label class="file-label">
                  <input class="file-input" type="file" @change="uploadCourseIcon( $event )">
                  <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                 {{ $t('courses.chooseIcon') }}
                </span>
              </span>
                </label>
              </div>
            </div>
            <div class="column is-half">
              <b-input :placeholder="$t('courses.name')" class="control" v-model="selectedCourse.name"/>
              <b-select v-model="selectedCourse.report_type" :expanded="true" style="margin-top: 8px;">
                <option value="off">{{ $t('courses.protocolDisabled') }}</option>
                <option value="ppla">{{ $t('courses.pplaProtocol') }}</option>
                <option value="kwt">{{ $t('courses.kwtProtocol') }}</option>
                <option value="a2">Protokół A2</option>
                <option value="radio">Protokół na radio</option>
                <option value="nsts01">Protokół NSTS-01</option>
                <option value="aircraft">Protokół na typ</option>
              </b-select>
              <b-input :placeholder="$t('courses.quizCategory')" class="control" style="margin-top: 8px;"
                       v-model="selectedCourse.quiz_category"/>
              <b-switch class="control" style="margin-top: 12px;"
                        v-model="selectedCourse.simulators_enabled" :true-value="1" :false-value="0">Dostępne
                symulatory?
              </b-switch>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div id="trumbowyg"></div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <button class="button is-danger" @click="deleteCourse(selectedCourse)"
                      v-if="$api.user.is_admin || $api.user.is_writer">
                {{ $t('courses.destroy') }}
              </button>
            </div>
            <div class="column" align="right">
              <button class="button is-primary is-inverted mr-2" @click="showEditingModal = false">Anuluj</button>
              <button class="button is-success" @click="save">
                {{ $t('courses.save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';

export default {
  name: "Courses",
  components: {HomeWrapper, Trumbowyg},
  data() {
    return {
      courses: [],
      showEditingModal: false,
      selectedCourse: null,
      isNew: false,
      assignedCoursesIds: []
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.courses = (await this.$api.getCourses()).data;
      const profile = await this.$api.getProfile();
      this.assignedCoursesIds = JSON.parse(profile.assigned_courses.length > 0 ? profile.assigned_courses : '[]').filter(x => x[1] === 'on').map(x => x[0]);
    },
    editNewCourse() {
      this.selectedCourse = {
        name: "",
        description: "",
        icon_url: "",
        id: 0,
        report_type: 'off',
        quiz_category: ''
      };
      this.isNew = true;
      this.editCourse(this.selectedCourse);
    },
    goToCourse(course) {
      window.location.replace(`/courses/${course.id}`);
    },
    editCourse(course) {
      this.selectedCourse = Object.assign({}, course, {});
      if (this.selectedCourse.report_type == null || this.selectedCourse.report_type.length === 0) this.selectedCourse.report_type = 'off';
      this.showEditingModal = true;
      const self = this;
      setTimeout(() => {
        require('./../assets/upload.js');
        jQuery('#trumbowyg').trumbowyg({
          btns: [
            ['viewHTML'],
            ['undo', 'redo'], // Only supported in Blink browsers
            ['formatting'],
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['link'],
            ['insertImage', 'upload'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            ['removeformat'],
            ['fullscreen']
          ],
          plugins: {
            upload: {
              serverPath: `${self.$api.config.endpoint}uploads`,
              fileFieldName: 'asset',
              urlPropertyName: 'url'
            }
          }
        });
        jQuery('#trumbowyg').trumbowyg('html', this.selectedCourse.description);
      }, 100);
    },
    async uploadCourseIcon(event) {
      const file = event.target.files[0];
      this.selectedCourse.icon_url = await this.$api.uploadAsset(file);
    },
    async save() {
      this.selectedCourse.description = jQuery('#trumbowyg').trumbowyg('html');
      if (this.isNew) {
        this.selectedCourse = await this.$api.createCourse(this.selectedCourse);
        this.isNew = false;
      }
      await this.$api.editCourse(this.selectedCourse);
      this.showEditingModal = false;
      await this.load();
    },
    deleteCourse(course) {
      this.$buefy.snackbar.open({
        message: `Jesteś pewien usunięcia kursu ${course.name}?`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Usuń teraz!',
        indefinite: true,
        onAction: async () => {
          await this.$api.deleteCourse(course);
          this.showEditingModal = false;
          await this.load();
        }
      })
    }
  }
}
</script>

<style>
.shadow-on-hover {
  transition: box-shadow .2s;
  cursor: pointer;
}

.shadow-on-hover:hover {
  /*transform: scale(1.05);*/
  box-shadow: 0 0 11px rgba(33, 33, 33, .2) !important;
}

.link:hover {
  text-decoration: underline;
}

.bumped-on-hover:hover {
  filter: brightness(90%);
}

.disabled-course {
  opacity: 0.6;
  pointer-events: none
}
</style>
